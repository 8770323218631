@use 'src/css/breakpoint.scss' as *;

.indexSection {
  position: relative;
  margin-bottom: 80px;

  @include maxMediaWidth(sm) {
    margin: 70px 0 40px;
  }

  &.sectionsWithBackground {
    margin: 0 auto;
    padding: 65px 0;

    @include maxMediaWidth(lg) {
      padding: 30px 0;
    }

    @include maxMediaWidth(sm) {
      padding: 25px 15px;
    }
  }
}

.innerContainer {
  @include maxMediaWidth(lg) {
    padding-right: 0;
    padding-left: 0;
  }

  @include maxMediaWidth(sm) {
    padding-right: 15px;
    padding-left: 15px;
  }
}

.title {
  text-align: center;
  padding-bottom: 60px;

  @include maxMediaWidth(lg) {
    padding-bottom: 30px;
  }

  @include maxMediaWidth(sm) {
    text-align: left;
    padding-bottom: 25px;
  }
}

.slider {
  :global(.swiper-wrapper) {
    align-items: stretch;
  }

  :global(.swiper-slide) {
    display: flex;
    justify-content: center;
    height: auto;
  }
}

.controlsWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;

  @include maxMediaWidth(lg) {
    display: none;
  }

  @include maxMediaWidth(sm) {
    display: flex;
  }
}

.pagination {
  display: flex;
  justify-content: center;
  font: var(--font-h8);
  color: var(--pagination-numbers-dark-color);

  :global(.swiper-pagination-current) {
    color: var(--secondary-dark-color);
  }

  &:global(.swiper-pagination-disabled),
  &:global(.swiper-pagination-hidden) {
    display: none;
  }
}

.prevControl,
.nextControl {
  @include minMediaWidth(lg) {
    display: block;
  }
}

.wrapLink {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 48px;
}
