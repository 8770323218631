@use 'src/css/breakpoint.scss' as *;

.wrap {
  display: flex;
  flex-direction: column;
  background: var(--news-bg-dark-color);
  border-radius: 8px;
  width: 100%;
  overflow: hidden;

  &,
  &:hover {
    text-decoration: none;
    color: unset;
  }
}

.content {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  padding: 31px 15px 25px;
}

.imgWrap {
  position: relative;
  width: 100%;
  height: 183px;

  .image {
    object-position: center;
    object-fit: cover;
  }

  &:hover {
    background: var(--color-primary-alpha-20);
  }
}

.title {
  margin-bottom: 10px;
  font: var(--font-h7);
  color: var(--main-title-color);

  @include maxMediaWidth(lg) {
    font: var(--font-h6-md);
  }

  @include maxMediaWidth(sm) {
    font: var(--font-h6-sm);
  }
}

.text {
  flex: 1 1 72px;
  margin-bottom: 15px;
  font: var(--font-h9);
  color: var(--secondary-text-color);

  overflow: hidden;
  text-overflow: ellipsis;
}

.date {
  font: var(--font-h11);
  color: var(--secondary-text-color);
}
